

























































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
// import { getView } from '@/utils/helpers';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, getView } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
import accountModule from '@/store/modules/accountModule';
import { param } from 'jquery';
import cellRender from './CellRender.js';
// import CellRender from './CellRender.js';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
import CustomHeader from './header.js';
import TagSelect from './userTagCell.vue';
import UserSelect from './userSelectCell.vue';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    UserSettings: () => getComponent('common/UserSettings'),
    AgGridVue,
    cellRender,
    agColumnHeader1: CustomHeader,
    UserSelect,
    Multiselect,
    TasqStatsCard: () => getComponent('tasqs/TasqStatsCard'),
    TagSelect,
  },
})
export default class Groups extends Vue {
   windowWidth = window.innerWidth;

   activeTableID: any = null;

    dataLoading = false;

   activeWorkspaceID: any = null;

   deleteGroupPopup = false;


   deleteGroupParams: any = {};

   newColumnName = '';

   newGroupName = '';

  showCreateGroupModal =false;

  selectedWellNames = [];

  availableWellNames = [];

  tempGroups = []

   showColumnMenu = false;

  selectedTags: any = [];

  availableTags: any = []

   showWellMenu = false;

    context: any = null;

   newWellName = '';

   frameworkComponents: any = {};

   newCloumnType = '';

   columnDefs: any = [
     {
       field: 'wellName', pinned: 'left', sortable: true, filter: true, headerName: 'Well Name', width: 300, editable: true, checkboxSelection: true,
     },
     {
       field: 'Add Column +',
       sortable: true,
       pinned: 'right',
       headerComponent: 'agColumnHeader1',
       editable: false,

       initialWidth: 150,
       cellRendererFramework: 'cellRender',
       cellRendererParams: {
         clicked(field) {
           alert(`${field} was clicked`);
         },
       },
     },

   ];

     defaultColDef = {
       initialWidth: 250,
       sortable: true,
       editable: true,
       resizable: true,
     };

    rowData:any = [

    ];

     get isMobile() {
      return this.windowWidth < 769;
    }

    deleteGroup(group) {
      this.deleteGroupPopup = true;
      this.deleteGroupParams  = { group_id: group.ID };
     
    }

    async confirmDeleteGroup(){
      let params = this.deleteGroupParams;
      this.deleteGroupPopup = false;
       await scheduleModule.deleteGroup(params);
      await scheduleModule.getTableGroups({ table_id: this.activeTableID });
      this.deleteGroupParams = {};
    }

    getGroupTags(group) {
      // console.log(group.Columns.find(c => c.))
      console.log(group.Payload);
      return ['Tag1', 'Tag2'];
    }

    addTag(newTag) {
      if (newTag) {
        this.availableTags.push(newTag);
        this.selectedTags.push(newTag);
      }
    }

    get activeTableGroups() {
      return scheduleModule.activeTableGroups;
    }

    goToDetailPage(group) {
      console.log(group);
      this.$router.push({ name: 'GroupDetail', params: { id: group.ID } });
	  // @ts-ignore
      scheduleModule.setActiveGroup(group);
    }

    async createGroup() {
      const rowData = this.selectedWellNames.map((name) => ({ Well: name, Tags: this.selectedTags }));
      this.selectedWellNames.forEach(async (name) => {
         const wellTags = await scheduleModule.getTagsForWell({ node_id: name });
        let newtags = this.selectedTags.concat(wellTags.map((t) => JSON.parse(t)));
        newtags = newtags.map(t => t.toLowerCase())
        console.log(newtags)
        await scheduleModule.addTagsToWell({ node_id: name, tags: newtags });
      })

      const columnData = [
        {
          ColumnName: 'Well',
          ColumnType: 'WELL',
          CustomName: 'Well Name',
        },

        {
          ColumnName: 'Tags',
          ColumnType: 'TAGS',
          CustomName: 'Tags',
        },
      ];

      const groupObj:any = {
        username: accountModule.user.email.toLowerCase(),
        workspace_id: this.activeWorkspaceID,
        table_id: this.activeTableID,
        columns: JSON.stringify(columnData),
        payload: JSON.stringify(rowData),
        group_name: this.newGroupName,
        usage: 'ASSETS',
      };

      await scheduleModule.postNewTableGroupPage(groupObj);

      this.selectedTags = [];
      this.selectedWellNames = [];
      this.newGroupName = '';
      this.showCreateGroupModal = false;

      await scheduleModule.getTableGroups({ table_id: this.activeTableID });
    }

    get myView() {
      return this.windowWidth < 769 ? 'tasqs-mobile' : 'tasqs-desktop';
    }

    beforeMount() {
     
    }

    closeColumnDialog() {
      this.showColumnMenu = false;
    }

    get enabledWells() {
      return assetsModule.enabledWells;
    }

    async created() {
      this.dataLoading = true;
       workspaceModule.setActivePage('Groups');
      scheduleModule.setActivePage('Groups');
      this.context = { componentParent: this };
      // if(tasqsListModule.activePage !== "Groups"){
        tasqsListModule.setActivePage('Groups')
	    await tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM);
      // }
      //  console.log(tasqsListModule.tasqList)
      // if(!assetsModule.enabledWells){
        await assetsModule.getEnabledWells();
      // }
      await workspaceModule.getWorkspaces();
      

     

      // console.log(workspaceModule.activeWorkspace);
      const workspace: any = workspaceModule.activeWorkspace;
      if (workspace?.Tables.length < 1) {
        await scheduleModule.postNewWorkspaceTable({
		  username: accountModule.user.email.toLowerCase(),
		  // @ts-ignore
		  workspace_id: workspaceModule.activeWorkspace.ID,
		  table_title: 'Asset Table',
	  });
      } else {
        this.activeTableID = workspace?.Tables[0].ID;
        this.activeWorkspaceID = workspace?.Tables[0].WorkspaceID;
      }

      if (this.activeTableID) {
        await scheduleModule.getTableGroups({ table_id: this.activeTableID });
        const getAllWellTags = await scheduleModule.getTagsForWell({ node_id: null });
        // console.log(getAllWellTags)
        scheduleModule.setAllTags(getAllWellTags);
        // console.log(scheduleModule.availableTags)
        // const groups = (scheduleModule.activeTableGroups);
        // let payload = [];
        let tags: any = [];
        const allWellTags = scheduleModule.availableTags;
        // console.log(allWellTags)
          if (allWellTags.length) {
            tags = tags.concat(allWellTags);
          }

          if (tags && tags.length) {
            this.availableTags = [...new Set(tags)].sort();
          }
          this.availableWellNames = assetsModule.enabledWells.map((w) => w.wellName);
          this.dataLoading = false;
      }
    }

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    }

    beforeDestroy() {
      workspaceModule.setActivePage(null);
      scheduleModule.setActivePage(null);
      window.removeEventListener('resize', this.onResize);
    }

    addColumn() {
      console.log('add');
      console.log(this.newColumnName);
      if (this.newColumnName) {
        this.columnDefs.push({ field: this.newColumnName });
        this.newColumnName = '';
      }
    }

    addRow() {
      console.log('add');
      if (this.newWellName) {
        this.rowData.push({ wellName: this.newWellName });
        this.newWellName = '';
      }
    }

    onResize() {
      this.windowWidth = window.innerWidth;
    }
}

